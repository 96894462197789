import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InverterPickerWindowComponent } from '../inverter-picker-window/inverter-picker-window.component';
import { Solar, NotifyService } from '@websolar/ng-websolar';
import { AIKO } from 'src/app/types/aiko.types';


@Component({
    selector: 'app-inverter-picker',
    templateUrl: './inverter-picker.component.html',
    styleUrls: ['./inverter-picker.component.scss']
})
export class InverterPickerComponent implements OnChanges {

    @Input() inverter!: Solar.Inverter;

    @Input() project!: AIKO.ProjectExt;

    @Input() systemSize!: number;

    @Output() inverterChange = new EventEmitter<Solar.Inverter>();

    public inverterName = "";

    public isLoading = false;

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["inverter"]) {
            this.inverterName = this.displayFn(this.inverter);
        }
    }


    public onChanged() {

    }

    public displayFn(obj: unknown) {
        if (!obj) {
            return "";
        }
        if (typeof obj == "string") {
            return obj;
        }
        const inverter = obj as Solar.Inverter;
        return `${inverter.manufacturer} ${inverter.model} [${inverter.maxPower}kW]`;
    }


    public async openSearch(evt: MouseEvent) {

        evt.preventDefault();
        evt.stopPropagation();

        try {


            const dialogRef = this._matDialog.open(InverterPickerWindowComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    systemSize: (this.systemSize * 1.5) || 0,
                    voltLevel: this.project.voltageLevel
                }
            });
            const res = await dialogRef.afterClosed().toPromise() as Solar.Inverter;
            if (!res) {
                return;
            }
            this.inverter = res;
            this.inverterChange.emit(res);

            this.inverterName = this.displayFn(this.inverter);
        }
        catch (err) {
            this._notify.error(err);
        }
    }
}
